'use strict';
// const ipfsClient = require('ipfs-http-client')
import axios from 'axios';
// const request = require('request')
// import store from '@/store/index'

var MFP_API;
if (process.env.VUE_APP_ENVIRONMENT === 'production') {
  console.log('API PRODUCTION ENVIRONMENT');
  MFP_API = process.env.VUE_APP_MFP_API;
} else if (process.env.VUE_APP_ENVIRONMENT === 'staging') {
  console.log('API TEST ENVIRONMENT');
  MFP_API = process.env.VUE_APP_MFP_API_TEST;
} else {
  console.log('API LOCAL ENVIRONMENT');
  MFP_API = process.env.VUE_APP_MFP_API_LOCAL;
}

var CATALOG_API;
if (process.env.VUE_APP_ENVIRONMENT === 'production') {
  console.log('API PRODUCTION ENVIRONMENT');
  CATALOG_API = process.env.VUE_APP_CATALOG_API;
} else if (process.env.VUE_APP_ENVIRONMENT === 'staging') {
  console.log('API TEST ENVIRONMENT');
  CATALOG_API = process.env.VUE_APP_CATALOG_API_TEST;
} else {
  console.log('API LOCAL ENVIRONMENT');
  CATALOG_API = process.env.VUE_APP_CATALOG_API_LOCAL;
}

let getters = null;
class MolaMfpAPI {
  constructor() {
    getters = getters || this;
    return getters;
  }

  // ###########################################################################
  // ############################ NEW RAPID API ################################
  // ###########################################################################

  async getEventsFromRapidApi(league) {
    const options = {
      method: 'GET',
      url: 'https://mma-api1.p.rapidapi.com/getEventId',
      params: {
        league: league,
        year: '2024'
      },
      headers: {
        'X-RapidAPI-Key': '891db3e124msh0c3c3aaa8168e0fp141fe9jsn3e563ec265ba',
        'X-RapidAPI-Host': 'mma-api1.p.rapidapi.com'
      }
    };
    
    try {
      return await axios.request(options);
      // console.log(response.data);
    } catch (error) {
      return error;
    }
  }

  async getEventsByIdFromRapidApi(eventId) {
    const options = {
      method: 'GET',
      url: 'https://mma-api1.p.rapidapi.com/scoreboard-by-event',
      params: {
        eventId: eventId
      },
      headers: {
        'X-RapidAPI-Key': '891db3e124msh0c3c3aaa8168e0fp141fe9jsn3e563ec265ba',
        'X-RapidAPI-Host': 'mma-api1.p.rapidapi.com'
      }
    };
    
    try {
      return await axios.request(options);
      // console.log(response.data);
    } catch (error) {
      return error;
    }
  }

  // ####################################################################
  // ######################### ADD FILES ################################
  // ####################################################################

  getFighterDetails() {
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
    return axios
      .get(MFP_API + 'mfp/fighter', config)
      .then((response) => {
        return response;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getFighterDetailsMain(name) {
    // const requestBody = {
    //     name: name,
    //   };
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
    return axios
      .get(
        'https://liveapi.yext.com/v2/accounts/me/answers/vertical/query?experienceKey=answers-en&api_key=850a88aeb3c29599ce2db46832aa229f&v=20220511&version=PRODUCTION&locale=en&input=' + name + '&verticalKey=athletes&limit=1',
          config
        /* 'https://liveapi.yext.com/v2/accounts/me/answers/vertical/query?v=20220511&api_key=850a88aeb3c29599ce2db46832aa229f&experienceKey=answers-en&locale=en&filters=%7B%22name%22%3A%7B%22%24eq%22%3A%22' + name + '%22%7D%7D&verticalKey=athletes&input=' + name,
        config */
      )
      .then((response) => {
        return response;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getFighterDetailsRetry(name) {
    // const requestBody = {
    //     name: name,
    //   };
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
    return axios
      .get(
        /* 'https://liveapi.yext.com/v2/accounts/me/answers/vertical/query?experienceKey=answers-en&api_key=850a88aeb3c29599ce2db46832aa229f&v=20220511&version=PRODUCTION&locale=en&input=' + name + '&verticalKey=athletes&limit=1',
          config */
        'https://liveapi.yext.com/v2/accounts/me/answers/vertical/query?v=20220511&api_key=850a88aeb3c29599ce2db46832aa229f&experienceKey=answers-en&locale=en&verticalKey=athletes&input=' + name + '&filters=%7B%22name%22%3A%7B%22%24eq%22%3A%22' + name + '%22%7D%7D',
        config
      )
      .then((response) => {
        return response;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getNumJobs() {
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
    return axios
      .get(MFP_API + 'mola/numjobs', config)
      .then((response) => {
        // console.log(response)
        return response;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  updatePosterAccount(id) {
    const requestBody = {
      id: id,
    };
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
    return axios
      .post(MFP_API + 'mola/updateaccount', requestBody, config)
      .then((response) => {
        // console.log(response)
        return response;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  // ####################################################################
  // ################### MOLA SUBSCRIBER API  CALLS #####################
  // ####################################################################
  getSubscriptionStatus(email) {
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
    return axios
      .get(MFP_API + 'users/mola?email=' + email, config)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((e) => {
        console.log(e);
      });
  }
  // ####################################################################
  // ################### GET MOLA POINTS FROM LEDGER ####################
  // ####################################################################
  async getMolaPointsFromLedger(uid) {
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
    return await axios.get(MFP_API + 'users/molapoints?uid=' + uid, config);
  }
  // ####################################################################
  // ################### GET MOLA POINTS FROM LEDGER ####################
  // ####################################################################
  getServerTime() {
    // const requestBody = {
    //     name: name,
    //   };
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
    return (
      axios
        // http://worldtimeapi.org/api/timezone/Etc/UTC
        .get(MFP_API + 'users/getservertime', config)
        .then((response) => {
          return response;
        })
        .catch((e) => {
          console.log(e);
        })
    );
  }

  getUserRecap(uid) {
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
    return (
      axios
        // http://worldtimeapi.org/api/timezone/Etc/UTC
        .get(MFP_API + `users/getuserrecap?uid=${uid}`, config)
        .then((response) => {
          return response;
        })
        .catch((e) => {
          console.log(e);
        })
    );
  }

  getAdminDashboard() {
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
    return (
      axios
        .get(MFP_API + `admin/dashboard`, config)
        .then((response) => {
          return response;
        })
        .catch((e) => {
          console.log(e);
        })
    );
  }

  getReportingByEvent(payload) {
    const requestBody = {
      events: payload.events
    };
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
    return (
      axios
        .post(MFP_API + `report/by-event-total`, requestBody, config)
        .then((response) => {
          return response;
        })
        .catch((e) => {
          console.log(e);
        })
    );
  }

  getReportingDetailByEvent(payload) {
    const requestBody = {
      events: payload.events
    };
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
    return (
      axios
        .post(MFP_API + `report/by-event`, requestBody, config)
        .then((response) => {
          return response;
        })
        .catch((e) => {
          console.log(e);
        })
    );
  }

  // Prediction Analytics
  getPredictionsDetailByEvent(payload) {
    const requestBody = {
      events: payload.events
    };
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
    return (
      axios
        .post(MFP_API + `report/prediction-detail-by-event`, requestBody, config)
        .then((response) => {
          return response;
        })
        .catch((e) => {
          console.log(e);
        })
    );
  }

  // Prediction By Event and Fight Result
  getPredictionDataByEventAndFightResult(payload) {
    const requestBody = {
      events: payload.events,
      fight_id: payload.fight_id,
      result_outcome: payload.result_outcome,
      result_method: payload.result_method,
      result_rounds: payload.result_rounds,
    };
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
    return (
      axios
        .post(MFP_API + `report/prediction-detail-by-event-and-fight-result`, requestBody, config)
        .then((response) => {
          return response;
        })
        .catch((e) => {
          console.log(e);
        })
    );
  }

  getNewPlayerReport(params) {
    const { eid, eventCreated, eventEnded } = params
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
    return (
      axios
        .get(MFP_API + `report/new-user-report?eid=${eid}&eventCreated=${eventCreated}&eventEnded=${eventEnded}`,
          config)
        .then((response) => {
          return response;
        })
        .catch((e) => {
          console.log(e);
        })
    );
  }
  // Mola Subscriber Report
  getMolaSubscribers(payload) {
    const requestBody = {
      mola_sub_type: payload.mola_sub_type
    };
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
    return (
      axios
        .post(MFP_API + `report/mola-subscriber-report`, requestBody, config)
        .then((response) => {
          return response;
        })
        .catch((e) => {
          console.log(e);
        })
    );
  }

  downloadExcel(headers, data){
    const requestBody = {
      headers: headers,
      data: data
    };
    const config = {
      responseType: 'arraybuffer'
    };
    console.log(requestBody);
    return (
      axios
        .post(MFP_API + `report/download-excel`, requestBody, config)
        .then((response) => {
          return response;
        })
        .catch((e) => {
          console.log(e);
        })
    );
  }

  downloadExcelPredictions(headers, data){
    const requestBody = {
      headers: headers,
      data: data
    };
    const config = {
      responseType: 'arraybuffer'
    };
    console.log(requestBody);
    return (
      axios
        .post(MFP_API + `report/download-excel-predictions`, requestBody, config)
        .then((response) => {
          return response;
        })
        .catch((e) => {
          console.log(e);
        })
    );
  }
  downloadExcelPredictionsByFight (headers, data){
    const requestBody = {
      headers: headers,
      data: data
    };
    const config = {
      responseType: 'arraybuffer'
    };
    console.log(requestBody);
    return (
      axios
        .post(MFP_API + `report/download-excel-predictions-by-fight`, requestBody, config)
        .then((response) => {
          return response;
        })
        .catch((e) => {
          console.log(e);
        })
    );
  }
  

  downloadExcelSubscribers(headers, data){
    const requestBody = {
      headers: headers,
      data: data
    };
    const config = {
      responseType: 'arraybuffer'
    };
    console.log(requestBody);
    return (
      axios
        .post(MFP_API + `report/download-excel-subscribers`, requestBody, config)
        .then((response) => {
          return response;
        })
        .catch((e) => {
          console.log(e);
        })
    );
  }

  downloadDuelReport(eventId){
    const config = {
      responseType: 'arraybuffer'
    };
    return (
      axios
        .get(MFP_API + `report/duel?eid=${eventId}`, config)
        .then((response) => {
          return response;
        })
        .catch((e) => {
          console.log(e);
        })
    );
  }
  
  downloadWeeklyPlayers (data){
    const requestBody = {
      data: data
    };
    const config = {
      responseType: 'arraybuffer'
    };
    console.log(requestBody);
    return (
      axios
        .post(MFP_API + `report/download-excel-new-user`, requestBody, config)
        .then((response) => {
          return response;
        })
        .catch((e) => {
          console.log(e);
        })
    );
  }

  downloadWeeklyRedeemReport (data){
    const requestBody = {
      data: data
    };
    const config = {
      responseType: 'arraybuffer'
    };
    console.log(requestBody);
    return (
      axios
        .post(MFP_API + `report/download-excel-weekly-redeem`, requestBody, config)
        .then((response) => {
          return response;
        })
        .catch((e) => {
          console.log(e);
        })
    );
  }

  downloadLeaderboardReport (data, type){
    const requestBody = {
      data: data,
      type: type
    };
    const config = {
      responseType: 'arraybuffer'
    };
    console.log(requestBody);
    return (
      axios
        .post(MFP_API + `report/download-leaderboard-weekly-fun`, requestBody, config)
        .then((response) => {
          return response;
        })
        .catch((e) => {
          console.log(e);
        })
    );
  }

  downloadAllTimeReport(reportType){
    const config = {
      responseType: 'arraybuffer'
    };
    const axiosInstance = axios.create({
      baseURL: MFP_API,  // Your base URL
      timeout: 180000  // Timeout set to 180 seconds (180,000 milliseconds)
    });
    return (
      axiosInstance
        .get(`report/download-all-time-report/${reportType}`, config)
        .then((response) => {
          return response;
        })
        .catch((e) => {
          console.log(e);
        })
    );
  }

  getBlob(){
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
    return (
      axios
        // http://worldtimeapi.org/api/timezone/Etc/UTC
        .get(MFP_API + 'share', config)
        .then((response) => {
          return response;
        })
        .catch((e) => {
          console.log(e);
        })
    );
  }
  getBlobBase64(data){
    return (
      axios
        .post(MFP_API + 'share', data)
        .then((response) => {
          return response;
        })
        .catch((e) => {
          console.log(e);
        })
    );
  }

  // ####################################################################
  // ##################### MOLA CATALOG API  CALLS ######################
  // ####################################################################

  // sync profile
  async syncProfile(payload) {
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
    return axios
      .post(CATALOG_API + 'profile/sync-profile', payload, config)
      .then((response) => {
        return response;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getOrderById(orderId, userId){
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      }
    };
    return (
      axios
        .get(CATALOG_API + `order/get-order-by-id?eid=${orderId}&uid=${userId}`, config)
        .then((response) => {
          return response;
        })
        .catch((e) => {
          console.log(e);
        })
    );
  }
}
export default new MolaMfpAPI();
